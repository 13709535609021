import React from "react";
import { Container } from "react-bootstrap";
import webdevimg from "../../../assets/img/services/web-application-development.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const WebDevelopment = () => {
  return (
    <>
    <section className="serv-banner">
      <Container>
        <Row>
          <Col className="mb-lg-0 mb-4" lg={7}>
            <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
              Web App Development
            </h2>

            <p>
              We offer Web app development services encompass a range of
              activities involved in creating web-based applications that run on
              web browsers. These services are essential for businesses and
              organizations seeking to establish a strong online presence and
              provide interactive and dynamic experiences to their users.
            </p>
            <p>Here are some key components of Web App Development services:</p>
            <ul>
              <li>Consultation and Planning</li>
              <li>UI/UX Design</li>
              <li>Front-End Development</li>
              <li>Back-End Development</li>
              <li>Database Integration</li>
              <li>API Development</li>
              <li>Security Implementations</li>
              <li>Testing and Quality Assurance</li>
              <li>Deployment</li>
              <li>Maintenance and Support</li>
              <li>Responsive Design</li>
              <li>Scalability Planning</li>
              <li>Cross-Browser Compatibility</li>
              <li>Analytics Integration</li>
            </ul>
            <p>
              We aim to deliver reliable, scalable, and user-friendly
              applications that meet the specific needs of clients and
              contribute to their business success.
            </p>
          </Col>
          <Col lg={5}>
            <div className="serv-img">
              <img
                variant="top"
                className="img-fluid"
                alt={webdevimg}
                src={webdevimg}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <hr className="hr-border m-0" />
    </>
  );
};

export default WebDevelopment;
