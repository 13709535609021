import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/logo/latest-logo.png";
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [isActive, setActive] = useState(false);

  const hideNav = ()=>{
    setActive(!isActive);
  }
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 0;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  return (
    <Navbar
      expand="lg"
      className={`header position-fixed w-100 ${
        scroll ? "nav-scroll " : "bg-white "
      }`}
    >
      <Container>
        <Navbar.Brand href="#" className="p-0">
          <NavLink to="/" className="text-decoration-none">
            <img src={logo} alt="banner-img" className="img-fluid" />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={`nav-toggle ${isActive ? "toggleOpen" : ""}`}
        >
          <span
            className="bar d-block position-relative"
            onClick={hideNav}
          ></span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="navbarScroll"
          className={`${isActive ? "menuOpen" : ""}`}
        >
          <Nav className="ms-auto my-2 my-lg-0 align-items-center" navbarScroll>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "active position-relative nav-top-link text-decoration-none"
                  : " position-relative nav-top-link text-decoration-none"
              }
            >
              Home
            </NavLink>
            <NavDropdown title="Services" id="basic-nav-dropdown" className="text-dark nav-top-link">
              <NavLink to="/services" className="text-decoration-none">
                All
              </NavLink>
              <NavLink to="/web-development" className="text-decoration-none">
              Web App Development
              </NavLink>
              <NavLink to="/mobile-development" className="text-decoration-none">
              Mobile App Development
              </NavLink>
              <NavLink to="/ui-ux-devlopment" className="text-decoration-none">
              UI UX Development
              </NavLink>
              <NavLink to="/hire-developers" className="text-decoration-none">
              Hire Developers
              </NavLink>
              <NavLink to="/digital-marketing" className="text-decoration-none">
              Digital Marketing
              </NavLink>
              <NavLink to="/on-demand-solutions" className="text-decoration-none">
              On Demand Solution
              </NavLink>
              
            </NavDropdown>

            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "active position-relative nav-top-link text-decoration-none"
                  : " position-relative nav-top-link text-decoration-none"
              }
            >
              About US
            </NavLink>

            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "active position-relative nav-top-link text-decoration-none"
                  : "position-relative nav-top-link text-decoration-none"
              }
            >
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
