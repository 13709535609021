import React from "react";
import { Container } from "react-bootstrap";
import ondemand from "../../../assets/img/services/On-Demand-Service-Apps.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const OnDemandSolution = () => {
  return (
    <>
      <section className="serv-banner">
        <Container>
          <Row>
            <Col className="mb-lg-0 mb-4" lg={7}>
              <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
                On Demand Solutions
              </h2>
              <p>
                Providing an on-demand solution involves creating a system that
                allows users to access and utilize services or resources
                instantly as needed. These solutions are commonly associated
                with applications and platforms that provide various services on
                demand.
              </p>
              <p>
                We follow key components and considerations for building an
                on-demand solution as given here:
              </p>
              <ul>
                <li>Define Your Service</li>
                <li>User Interface (UI) and User Experience (UX)</li>
                <li>Mobile Compatibility</li>
                <li>Real-Time Functionality</li>
                <li>Security Measures</li>
                <li>Payment Integration</li>
                <li>Scalability</li>
                <li>Backend Infrastructure</li>
                <li>APIs and Integration</li>
                <li>Analytics and Reporting</li>
                <li>Feedback Mechanism</li>
                <li>Compliance and Regulations</li>
                <li>Testing and Quality Assurance</li>
                <li>Customer Support</li>
                <li>Legal Considerations</li>
              </ul>
              <p>
                Whether you are developing a cloud computing platform, a
                software-as-a-service (SaaS) application, or any other on-demand
                IT solution, careful planning and execution are essential for
                success. We keep user needs in mind and continuously iterate
                based on user feedback and market trends.
              </p>
            </Col>
            <Col lg={5}>
              <div className="serv-img">
                <img
                  variant="top"
                  className="img-fluid"
                  alt={ondemand}
                  src={ondemand}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <hr className="hr-border m-0" />
    </>
  );
};

export default OnDemandSolution;
