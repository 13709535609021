import React from "react";
import { Container } from "react-bootstrap";
import mobileui from "../../../assets/img/services/mobile-app-development.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MobileDevelopment = () => {
  return (
    <>
      <section className="serv-banner">
        <Container>
          <Row>
            <Col className="mb-lg-0 mb-4" lg={7}>
              <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
                Mobile App Development
              </h2>
              <p>
                We provide Mobile app development services involve the creation
                of applications specifically designed for mobile devices, such
                as smartphones and tablets. These services cover the entire app
                development lifecycle, from conceptualization to deployment
              </p>
              <p>Here are key components of mobile app development services:</p>
              <ul>
                <li>Consultation and Strategy</li>
                <li>Market Research</li>
                <li>UI/UX Design</li>
                <li>Platform Selection</li>
                <li>Native and Cross-Platform Development</li>
                <li>Front-End Development</li>
                <li>Back-End Development</li>
                <li>Database Integration</li>
                <li>API Development</li>
                <li>Security Implementation</li>
                <li>Testing and Quality Assurance</li>
                <li>Deployment to App Stores</li>
                <li>Post-Launch Support</li>
                <li>Performance Optimization</li>
                <li>Analytics Integration</li>
              </ul>
              <p>
                We aim to deliver high-quality, feature-rich applications that
                meet user expectations and contribute to the success of
                businesses or organizations in the mobile space.
              </p>
            </Col>
            <Col lg={5}>
              <div className="serv-img">
                <img
                  variant="top"
                  className="img-fluid"
                  alt={mobileui}
                  src={mobileui}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <hr className="hr-border m-0" />
    </>
  );
};

export default MobileDevelopment;
