import React from 'react'
import { Container } from 'react-bootstrap'
import hireimg from '../../../assets/img/services/hire-developers.png'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HireDevelopment = () => {
  return (
    <>
    <section className='serv-banner'>
      <Container>
        <Row>
          <Col className="mb-lg-0 mb-4" lg={7}>
            <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
            Hire Developers
            </h2>
            
            <p>
              We can certainly help guide you on the process of hiring developers. 
            </p>
            <p>Here are some general steps you can follow:</p>
            <ul>
            <li>Define Your Needs</li>
            <li>Create a Job Description</li>
            <li>Post on Job Boards</li>
            <li>Utilize Networking</li>
            <li>Review Resumes and Portfolios</li>
            <li>Conduct Interviews</li>
            <li>Technical Assessment</li>
            <li>Check References</li>
            <li>Discuss Compensation and Benefits</li>
            <li>Onboarding</li>
            </ul>
            <p>We are transparent throughout the hiring process and provide feedback to candidates, whether they are successful or not.
               Additionally, consider the long-term growth and development opportunities for your new hires to foster a positive work environment.</p>
          </Col>

          <Col lg={5}>
            <div className="serv-img">
          <img variant="top" className='img-fluid' alt={hireimg} src={hireimg} />
            </div>
            </Col>
        </Row>
      </Container>
    </section>
    <hr className="hr-border m-0" />
    </>
  )
}

export default HireDevelopment
