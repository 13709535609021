import React from "react";
import { Container } from "react-bootstrap";
import uiuximg from "../../../assets/img/services/UI-UX-Development.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UiuxDevlopment = () => {
  return (
    <>
    <section className="serv-banner">
      <Container>
        <Row>
          <Col className="mb-lg-0 mb-4" lg={7}>
            <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
              UI UX Development
            </h2>

            <p>
              We offer a full cycle of UI/UX development, often referred to as
              user interface (UI) design and user experience (UX) design, plays
              a crucial role in creating software applications, websites, and
              digital products that are both aesthetically pleasing and
              user-friendly. Whether it is a consumer oriented app or a
              transformative enterprise-class solution, the company leads the
              entire UI UX development process from ideation and concept to
              delivery, and to ongoing ongoing support.
            </p>
            <p>Here's our overview of UI/UX development process :</p>
            <h6>User Interface (UI) Development</h6>
            <ul>
              <li>Wireframing</li>
              <li>Prototyping</li>
              <li>Visual Design</li>
              <li>Responsive Design</li>
              <li>Iconography</li>
              <li>Animation and Interaction Design</li>
              <li>Style Guides and Design Systems</li>
            </ul>
            <h6>User Experience (UX) Development</h6>
            <ul>
              <li>User Research</li>
              <li>Persona Development</li>
              <li>Information Architecture</li>
              <li>Usability Testing</li>
              <li>User Flows</li>
              <li>Accessibility</li>
              <li>User Journey Mapping</li>
              <li>Feedback Integration</li>
              <li>A/B Testing</li>
              <li>User Onboarding</li>
              <li>Error Handling and Messaging</li>
            </ul>
            <p>
              We make sure our Effective UI/UX development keep collaboration
              between designers, developers, and stakeholders to create a
              seamless and enjoyable user experience. The goal is to design
              interfaces that not only look good but also prioritize user
              satisfaction, ease of use, and accessibility.
            </p>
          </Col>
          <Col lg={5}>
            <div className="serv-img">
              <img
                variant="top"
                className="img-fluid"
                alt={uiuximg}
                src={uiuximg}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <hr className="hr-border m-0" />
    </>
  );
};

export default UiuxDevlopment;
