import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomInputField from "../../CustomTags/CustomInputField";
import { Link } from "react-router-dom";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactUs.scss";

const ContactUs = () => {
  const [userState, setUserState] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  useEffect(() => {
    emailjs.init("_Q35GWD-iKwyp4567");
  }, []);

  const serviceId = "service_auv1ltf";
  const templateId = "template_trw38eg";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserState({ ...userState, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(serviceId, templateId, {
        to_name: userState.name,
        form_name: userState.email,
        message: `Phone: ${userState.phone} {' '} ${userState.comment}`,
        reply_to: userState.email,
      });
      setUserState({
        name: "",
        email: "",
        phone: "",
        comment: "",
      });
      alert("email successfully sent check inbox");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="contact-banner">
      <Container>
        <Row>
          <Col className="col-lg-7 col-12 pe-lg-5">
          <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">Contact Us</h2>
            <h6 className="text-muted">
              Want to discuss your project requirements with us? Fill the form
              below and we will contact you ASAP.
            </h6>
            <Form onSubmit={handleSubmit}>
              <CustomInputField
                placeholder="What's Your Name*"
                name="name"
                action={handleChange}
              />
              <CustomInputField
                placeholder="Enter Your Email Address*"
                name="email"
                action={handleChange}
              />
              <CustomInputField
                placeholder="Phone No"
                name="phone"
                action={handleChange}
              />
              <CustomInputField
                placeholder="How Can We Help You?*"
                name="comment"
                action={handleChange}
              />
              <div className="">
                <Button
                  className="rounded blue-btn blue-bg text-decoration-none text-white mt-4 border-0"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
          <Col className="col-lg-5 col-12 mt-lg-0 mt-5 ps-lg-5">
            <div className="contact-content">
              <h4 className="fw-bold">Want to contact us directly?</h4>

              <ul className="list-unstyled">
                <li className="mb-2 position-relative mt-4">
                  <h5 className="fw-medium text-dark">New Project Inquiries</h5>
                  <div className="contact-list">
                    <Link to="" className="text-decoration-none text-break">
                      <i className="fa-solid fa-envelope position-absolute"></i>{" "}
                      projectinquiries@simplefixit.com
                    </Link>
                  </div>
                </li>

                <li className="mb-2 position-relative mt-4">
                  <h5 className="fw-medium text-dark">Careers</h5>
                  <div className="contact-list">
                    <Link to="" className="text-decoration-none text-break">
                      <i className="fa-solid fa-envelope position-absolute"></i>{" "}
                      career@simplefixit.com
                    </Link>
                  </div>
                </li>

                <li className="mb-2 position-relative mt-4">
                  <h5 className="fw-medium text-dark">Other Enquries</h5>
                  <div className="contact-list">
                    <Link to="" className="text-decoration-none">
                      <i className="fa-solid fa-envelope position-absolute"></i>{" "}
                      inquiries@simplefixit.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
