import React from "react";
import { Container } from "react-bootstrap";
import digitalMarketing from "../../../assets/img/services/digital-marketing2.webp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DigitalMarketing = () => {
  return (
    <>
      <section className="serv-banner">
        <Container>
          <Row>
            <Col className="mb-lg-0 mb-4" lg={7}>
              <h2 className="fw-bold btm-border btm-border-left position-relative mb-4">
              Digital Marketing
              </h2>
              <p>
                Our Digital marketing process encompasses a broad range of
                strategies and tactics to promote products, services, or brands
                using digital channels.
              </p>
              <p>
                Here's a brief overview of key components and strategies within
                digital marketing:
              </p>
              <ul>
                <li>Search Engine Optimization (SEO)</li>
                <li>Content Marketing</li>
                <li>Social Media Marketing</li>
                <li>Email Marketing</li>
                <li>Pay-Per-Click (PPC) Advertising</li>
                <li>Affiliate Marketing</li>
                <li>Influencer Marketing</li>
                <li>Analytics and Data Analysis</li>
                <li>Mobile Marketing</li>
                <li>Chatbots and AI in Marketing</li>
                <li>Video Marketing</li>
                <li>Customer Relationship Management (CRM)</li>
              </ul>
              <p>
                When developing a digital marketing strategy, it's crucial to
                understand your target audience, set clear goals, and regularly
                evaluate and adjust your tactics based on performance metrics.
                Digital marketing is dynamic, and staying informed about
                industry trends is essential for success. We try to understand
                your target audience and work accordingly.
              </p>
            </Col>
            <Col lg={5}>
              <div className="serv-img">
                <img
                  variant="top"
                  className="img-fluid"
                  alt={digitalMarketing}
                  src={digitalMarketing}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <hr className="hr-border m-0" />
    </>
  );
};

export default DigitalMarketing;
