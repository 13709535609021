import React from 'react'

const Industries = () => {
  return (
    <div>
      <h2>Industries</h2>
    </div>
  )
}

export default Industries
