import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import softwareImg from "../../assets/img/software-img.svg";
import BannerImg from "../../assets/img/banner-img.svg";
import BannerImg2 from "../../assets/img/websites-app-development.png";
import BannerImg3 from "../../assets/img/digital-marketing2.png";
import { Carousel } from 'react-bootstrap';
import "./Home.scss";

const Home = () => {
  return (
    <>
      {/* banner-section */}
      <section className="banner">

        <Carousel>
          <Carousel.Item>
            <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="banner-content text-lg-start text-center">
                    <span className="title-tag rounded-pill py-1 px-2 mb-2 d-inline-block">
                      Welcome to SimpleFix IT
                    </span>
                    <h1 className="fw-bold mb-3">
                      Dedicated <span className="blue-color">IT</span> Support and{" "}
                      <span className="blue-color">Business </span> Solution
                    </h1>
                    <p>
                      At SimpleFix IT, we are a dynamic and forward-thinking IT company
                      that is passionate about helping businesses thrive in the digital era.
                      With our extensive expertise and dedication to excellence,
                      we aim to be your trusted partner in navigating the ever-changing landscape of technology.
                    </p>
                    <Link
                      to="/services"
                      className="rounded blue-btn blue-bg text-decoration-none text-white mt-4"
                      type="button"
                    >
                      Our Services
                    </Link>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="banner-img">
                    <img src={BannerImg} alt="banner-img" className="img-fluid d-lg-block d-none" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="banner-content text-lg-start text-center">
                    <span className="title-tag rounded-pill py-1 px-2 mb-2 d-inline-block">
                      Welcome to SimpleFix IT
                    </span>
                    <h1 className="fw-bold mb-3">
                      Web <span className="blue-color">&</span> App Development Company{" "}
                      <span className="blue-color">Business </span> Solution
                    </h1>
                    <p>
                      we are passionate about turning your ideas into powerful and engaging digital experiences.
                      As a leading web and mobile app development company, we specialize in crafting custom solutions that elevate your business to new heights.
                    </p>
                    <Link
                      to="/services"
                      className="rounded blue-btn blue-bg text-decoration-none text-white mt-4"
                      type="button"
                    >
                      Our Services
                    </Link>
                  </div>
                </Col>
                <Col lg={6} className="d-lg-block d-none">
                  <div className="banner-img">
                    <img src={BannerImg2} alt="banner-img" className="h-30 w-60 img-fluid" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="banner-content text-lg-start text-center">
                    <span className="title-tag rounded-pill py-1 px-2 mb-2 d-inline-block">
                      Welcome to SimpleFix IT
                    </span>
                    <h1 className="fw-bold mb-3">
                      Digital <span className="blue-color">Marketing</span> &  {"   "}
                      <span className="blue-color"> On Demand Solution</span>
                    </h1>
                    <p>
                      Our digital marketing experts craft tailored strategies to amplify your online presence.
                      From SEO to social media, we're here to enhance your brand visibility and drive meaningful engagement.
                      Also, Our solutions range from ride-sharing and food delivery apps to custom on-demand platforms that
                      cater to the evolving needs of modern consumers.
                    </p>
                    <Link
                      to="/services"
                      className="rounded blue-btn blue-bg text-decoration-none text-white mt-4"
                      type="button"
                    >
                      Our Services
                    </Link>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="banner-img">
                    <img src={BannerImg3} alt="banner-img" className="img-fluid d-lg-block d-none" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        </Carousel>
      </section>

      {/* services-section */}
      <section className="our-services bg-grey sec-pd">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="sec-header mb-4">
                <h2 className="fw-bold text-center btm-border position-relative">
                  Our Services
                </h2>
              </div>

              <Row>
                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                      <i className="fa-solid fa-arrows-spin"></i>
                    </span>
                    <h4>Web App Development</h4>
                    <p>
                      We offer top-notch Web App Development services to help businesses
                      navigate the complexities of technology and maximize their potential.
                      Our expert consultants work closely with you to understand your unique business needs,
                      goals, and challenges, providing tailored solutions that drive growth and efficiency.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                      <i className="fa-brands fa-cloudsmith"></i>
                    </span>
                    <h4>Mobile App Development</h4>
                    <p>
                      We excel in providing Mobile App Development services
                      to ensure a robust and reliable foundation for your business operations.
                      With our expertise and dedication, we create and maintain high-performance networks
                      that optimize efficiency and enhance productivity.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                      <i className="fa-solid fa-snowflake"></i>
                    </span>
                    <h4>UI UX Development</h4>
                    <p>
                      We specialize in providing cutting-edge UI UX Development services,
                      enabling businesses to harness the full potential of cloud technology. Our expert team ensures
                      a smooth and efficient transition to the cloud, empowering you to experience the numerous benefits it offers.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                    <i className="fa-brands fa-cloudsmith"></i>
                    </span>
                    <h4>Hire Developers</h4>
                    <p>
                      We specialize in hiring top notch Developers that empower businesses
                      to leverage technology for enhanced efficiency and innovation.
                      Our expert team of developers and integration specialists is committed
                      to delivering high-quality solutions tailored to meet your specific requirements and drive your business forward.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                      <i className="fa-solid fa-snowflake"></i>
                    </span>
                    <h4>Digital Marketing</h4>
                    <p>
                      We take Digital Marketing seriously, providing comprehensive solutions to promote your business
                      everywhere. Our expert team is dedicated to ensuring the confidentiality, integrity,
                      and availability of your sensitive information, allowing you to focus on your core operations with peace of mind.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-4">
                  <div className="service-innr rounded h-100 text-lg-start text-center">
                    <span className="service-icon mb-3 blue-color d-inline-block">
                    <i className="fa-solid fa-arrows-spin"></i>
                    </span>
                    <h4>On Demand Services</h4>
                    <p>
                      We support and provide On Demand Services, providing businesses with reliable technical
                      assistance and resolving IT-related issues promptly. Our expert team of support/developers professionals is
                      committed to ensuring your technology runs smoothly, enabling you to focus on your core business without
                      any interruptions.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Best Organisation Section */}
      <section className="our-team sec-pd">
        <Container>
          <Row className="align-items-center position-relative">
            <Col lg={6} className="">
              <div className="pe-lg-5 text-start">
                <span className="title-tag rounded-pill py-1 px-2 mb-2 d-inline-block">
                  We Offer
                </span>
                <h2 className="fw-bold mb-3">
                  Best
                  <span className="blue-color"> Strategic & Innovative </span>
                  Solutions to Grow
                </h2>
                <p className="team-heading">
                  Our focus on excellence and continuous innovation ensures that we deliver solutions that drive tangible
                  results and propel your organization towards success.
                </p>
                <ul className="list-unstyled listing text-start">
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Comprehensive Business Analysis
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Goal-Oriented Approach
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Competitive Advantage
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Long-Term Vision
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Technology Transformation
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Product and Service Innovation
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Process Optimization
                  </li>
                  <li className="position-relative">
                    <span className="check-icon blue-color d-inline-block">
                      <i className="fa-solid fa-circle-check fs-4"></i>
                    </span>
                    Customer-Centric Solutions
                  </li>
                </ul>
                <Link
                  to="/about"
                  className="rounded blue-btn blue-bg text-decoration-none text-white mt-4"
                  type="button"
                >
                  Explore More
                </Link>
              </div>
            </Col>
            <Col lg={6} className="stratagic-img d-md-block d-none">
              <div className="org-img ps-lg-5 text-lg-start text-center">
                <img
                  src={softwareImg}
                  alt="software-img"
                  className="img-fluid mx-lg-0 mx-auto"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* start-section */}
      <section className="start-sec sec-pd bg-grey">
        <Container>
          <Row>
            <Col>
              <div className="text-center">
                <span className="title-tag rounded-pill py-1 px-2 mb-2 d-inline-block">
                  Work Togethor
                </span>
                <h2 className="fw-bold">
                  Let’s Work Together On Your Next Project.
                </h2>
                <p className="mx-auto">
                  We're thrilled to collaborate with you on your next project! At SimpleFix IT,
                  we are dedicated to providing exceptional solutions and bringing your vision to life.
                  Whether you have a specific project in mind or need guidance in defining your requirements,
                  we are here to support you every step of the way.{" "}
                </p>
                <Link
                  to="/contact"
                  className="rounded blue-btn blue-bg text-decoration-none text-white mt-4"
                  type="button"
                >
                  Let's Get Started
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
